body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #000000;
  text-decoration: none;
  outline: none;
}

.bottom-part-offset {
  margin-left: 15%;
}

.header-arquitectura {
  font-weight: 200;
  font-size: 2em;
}

.header-popular {
  font-weight: 700;
  font-size: 2em;
}

.header-by {
  font-weight: 500;
  font-size: 1em;
}

.visits {
  margin-left: 70%;
  font-size: 0.8em;
  font-weight: 500;
  color: #9b9b9b;
}

.relative-div {
  width: 100%;
  height: 100%;
  position: relative;
}

.visits-number {
  font-size: 1.2em;
  font-weight: 500;
  color: #9b9b9b;
  position: absolute;
  bottom: 2px;
}

.visits-number-small {
  font-size: 1.2em;
  font-weight: 500;
  color: #9b9b9b;
}

.header-about {
  font-size: 1.5em;
  font-weight: 700;
  color: black;
  position: absolute;
  bottom: 0px;
  right: 2px;
}

.header-about-selected {
  font-size: 1.5em;
  font-weight: 700;
  color: black;
  border-bottom: 3px solid #d8d8d8;
  display: inline-block;
  position: absolute;
  bottom: -3px;
}

.header-about-small {
  font-size: 1.5em;
  font-weight: 700;
  color: black;
  position: absolute;
  right: 2px;
}

.header-about-selected-small {
  font-size: 1.5em;
  font-weight: 700;
  color: black;
  border-bottom: 3px solid #d8d8d8;
  display: inline-block;
  position: absolute;
  right: 2px;
}

.header-about:hover {
  color: #9b9b9b;
}

.pdf-container {
  width: 100%;
}

.pdf-viewer-div {
  background-color: darkgray;
  width: 100%;
  padding-bottom: 48%;
  position: relative;
}

.pdf-viewer-div-small {
  background-color: darkgray;
  width: 100%;
  padding-bottom: 80%;
  position: relative;
}

.pdf-iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.map-title {
  padding-top: 25px;
  font-size: 1.5em;
  font-weight: 500;
  padding-bottom: 10px;
}

.map-container {
  width: 100%;
  padding-bottom: 70%;
  background-color: red;
  position: relative;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.about-space {
  width: 100%;
  padding-bottom: 48%;
  position: relative;
}

.about-inside {
  position: absolute;
}

.bottom-title {
  width: 100%;
  font-weight: 700;
  font-size: 1.3em;
  padding-top: 25px;
  padding-bottom:12px;
}

.bottom-strong {
  font-weight: 700;
}

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 0.8em;
  color: #9b9b9b;
}

.download-counter {
  color: #9b9b9b;
  font-size: 1em;
  height: 100%;
  padding-top: 3%;
}

.download-icon-wrap {
  padding-bottom: 10px;
  padding-top: 2px;
}

.fat-elem {
  width: 100%;
}

.breadcrumb-home {
  color: #9b9b9b;
  font-size: 1em;
}

.breadcrumb-strong {
  color: #9b9b9b;
  font-size: 1em;
  font-weight: 500;
}

.english-about-title {
  padding-top: 25px;
  font-size: 1.5em;
  font-weight: 500;
  padding-bottom: 10px;
}

.english-about-content {
  font-weight: 500;
  text-align: justify;
  padding-right: 30px;
  font-size: 0.9em;
}

.spanish-about-title {
  padding-top: 25px;
  font-size: 1.5em;
  padding-bottom: 10px;
}

.spanish-about-content {
  text-align: justify;
  padding-right: 30px;
  font-size: 0.9em;
}

.breadcrumb-wrap {
  width: 100%;
}

.more-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}